import { A, Label } from 'common'
import { GetInTouch, Aurora } from 'components'
import { React, Helmet, styled } from 'x'

const Wrapper = styled.div`
  padding: 100px 50px 50px;
  max-width: 700px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  color: white;

  .gatsby-resp-image-wrapper {
    margin: 30px 0;
  }
`

const AuroraWrapper = styled.div`
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  z-index: 1;
  width: 100%;
  overflow: hidden;
`

const PrivacyPolicy = (props) => {
  return (
    <>
      <Helmet htmlAttributes={{ lang: 'nl' }}>
        <title>Privacy Policy</title>
        <meta name="description" content="" />
      </Helmet>
      <AuroraWrapper>
        <Aurora header={true} />
      </AuroraWrapper>
      <Wrapper>
        <Label>PRIVACY POLICY</Label>
        <p>
          Inno B.V., gevestigd op Hannie Dankbaarpassage 14 1053 RT Amsterdam, is verantwoordelijk voor de verwerking van persoonsgegevens zoals
          weergegeven in deze privacyverklaring.
        </p>
        PERSOONSGEGEVENS DIE WIJ VERWERKEN
        <br />
        <p>
          Inno verwerkt je persoonsgegevens doordat je gebruik maakt van onze diensten en/of omdat je deze gegevens zelf aan ons verstrekt. Hieronder
          vind je een overzicht van de persoonsgegevens die wij verwerken:
        </p>
        <p>
          Voor- en achternaam
          <br />
          Telefoonnummer
          <br />
          E-mailadres
          <br />
          Gegevens over jouw activiteiten op onze website
          <br />
          Internetbrowser en apparaat type
        </p>
        <p>BIJZONDERE EN/OF GEVOELIGE PERSOONSGEGEVENS DIE WIJ VERWERKEN</p>
        <p>
          Onze website en/of dienst heeft niet de intentie gegevens te verzamelen over websitebezoekers die jonger zijn dan 16 jaar. Tenzij ze
          toestemming hebben van ouders of voogd. We kunnen echter niet controleren of een bezoeker ouder dan 16 is. Wij raden ouders dan ook aan
          betrokken te zijn bij de online activiteiten van hun kinderen, om zo te voorkomen dat er gegevens over kinderen verzameld worden zonder
          ouderlijke toestemming. Als je er van overtuigd bent dat wij zonder die toestemming persoonlijke gegevens hebben verzameld over een
          minderjarige, neem dan contact met ons op via hello@inno.nl, dan verwijderen wij deze informatie.
        </p>
        <p>MET WELK DOEL EN OP BASIS VAN WELKE GRONDSLAG WIJ PERSOONSGEGEVENS VERWERKEN</p>
        <p>
          Inno verwerkt jouw persoonsgegevens voor de volgende doelen:
          <br />
          <br />
          Het afhandelen van jouw betaling
          <br />
          Je te kunnen bellen of e-mailen indien dit nodig is om onze dienstverlening uit te kunnen voeren
          <br />
          Je te informeren over wijzigingen van onze diensten en producten
          <br />
          Inno verwerkt ook persoonsgegevens als wij hier wettelijk toe verplicht zijn, zoals gegevens die wij nodig hebben voor onze
          belastingaangifte.
        </p>
        <p>GEAUTOMATISEERDE BESLUITVORMING</p>
        <p>
          Inno neemt niet op basis van geautomatiseerde verwerkingen besluiten over zaken die (aanzienlijke) gevolgen kunnen hebben voor personen. Het
          gaat hier om besluiten die worden genomen door computerprogramma’s of -systemen, zonder dat daar een mens (bijvoorbeeld een medewerker van
          Inno) tussen zit.
        </p>
        <p>HOE LANG WE PERSOONSGEGEVENS BEWAREN</p>
        <p>Inno bewaart je persoonsgegevens niet langer dan strikt nodig is om de doelen te realiseren waarvoor je gegevens worden verzameld.</p>
        <p>DELEN VAN PERSOONSGEGEVENS MET DERDEN</p>
        <p>
          Inno verkoopt jouw gegevens niet aan derden en zal deze uitsluitend verstrekken indien dit nodig is voor de uitvoering van onze overeenkomst
          met jou of om te voldoen aan een wettelijke verplichting. Met bedrijven die jouw gegevens verwerken in onze opdracht, sluiten wij een
          bewerkersovereenkomst om te zorgen voor eenzelfde niveau van beveiliging en vertrouwelijkheid van jouw gegevens. Inno blijft
          verantwoordelijk voor deze verwerkingen.
        </p>
        <p>COOKIES, OF VERGELIJKBARE TECHNIEKEN, DIE WIJ GEBRUIKEN</p>
        <p>
          Inno gebruikt functionele en analytische cookies. Een cookie is een klein tekstbestand dat bij het eerste bezoek aan deze website wordt
          opgeslagen in de browser van je computer, tablet of smartphone. Inno gebruikt cookies met een puur technische functionaliteit. Deze zorgen
          ervoor dat de website naar behoren werkt en dat bijvoorbeeld jouw voorkeursinstellingen onthouden worden. Deze cookies worden ook gebruikt
          om de website goed te laten werken en deze te kunnen optimaliseren.
        </p>
        <p>
          Je kunt je afmelden voor cookies door je internetbrowser zo in te stellen dat deze geen cookies meer opslaat. Daarnaast kun je ook alle
          informatie die eerder is opgeslagen via de instellingen van je browser verwijderen.
        </p>
        <p>
          Zie voor een toelichting:{' '}
          <A href="https://veiliginternetten.nl/themes/situatie/cookies-wat-zijn-het-en-wat-doe-ik-ermee/">
            https://veiliginternetten.nl/themes/situatie/cookies-wat-zijn-het-en-wat-doe-ik-ermee/
          </A>
        </p>
        <p>GEGEVENS INZIEN, AANPASSEN OF VERWIJDEREN</p>
        <p>
          Je hebt het recht om je persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast heb je het recht om je eventuele toestemming
          voor de gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van jouw persoonsgegevens door Inno en heb je het recht op
          gegevensoverdraagbaarheid. Dat betekent dat je bij ons een verzoek kan indienen om de persoonsgegevens die wij van jou beschikken in een
          computerbestand naar jou of een ander, door jou genoemde organisatie, te sturen.
        </p>
        <p>
          Je kunt een verzoek tot inzage, correctie, verwijdering, gegevensoverdraging van je persoonsgegevens of verzoek tot intrekking van je
          toestemming of bezwaar op de verwerking van jouw persoonsgegevens sturen naar hello@inno.nl.
        </p>
        <p>
          Om er zeker van te zijn dat het verzoek tot inzage door jou is gedaan, vragen wij jou een kopie van je identiteitsbewijs met het verzoek mee
          te sturen. Maak in deze kopie je pasfoto, MRZ (machine readable zone, de strook met nummers onderaan het paspoort), paspoortnummer en
          Burgerservicenummer (BSN) zwart. Dit ter bescherming van je privacy. We reageren zo snel mogelijk, maar binnen vier weken, op jouw verzoek.
        </p>
        <p>
          Inno wil je er tevens op wijzen dat je de mogelijkheid hebt om een klacht in te dienen bij de nationale toezichthouder, de Autoriteit
          Persoonsgegevens. Dat kan via de volgende link:{' '}
          <A href="https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons">
            https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons
          </A>
        </p>
        <p>HOE WIJ PERSOONSGEGEVENS BEVEILIGEN</p>
        <p>
          Inno neemt de bescherming van jouw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste
          openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als je het idee hebt dat jouw gegevens toch niet goed beveiligd zijn of er
          aanwijzingen zijn van misbruik, neem dan contact op via hello@inno.nl.
        </p>
        <p>
          Contactgegevens:
          <br />
          <br />
          hello@inno.nl
          <br />
          Hannie Dankbaarpassage 14
          <br />
          1053 RT Amsterdam
        </p>
      </Wrapper>
      <GetInTouch />
    </>
  )
}
export default PrivacyPolicy
